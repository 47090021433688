<template>
  <!-- start page title -->
  <div
    class="page-title-box d-xxl-flex align-items-center justify-content-between p-0 bg-white"
    style="position: fixed; width: 100%; z-index: 10; background: transparent; height: 53px;"
  >
    <b-col
      :xxl="leftSize"
      class="d-flex h-100"
      style="background-color: #F3F6F9; padding-left: 26px;"
    >
      <div class="d-flex h-100 w-100 pe-2">
        <slot name="left" />
      </div>
      <div class="d-flex d-xxl-none pe-2">
        <div
          class="d-flex h-100"
          type="button"
          @click="showFilter = !showFilter"
        >
          <i
            style="transition-duration: 200ms;"
            :style="showFilter ? 'transform: rotate(180deg);' : ''"
            class="bx bx-chevron-down my-auto fs-2"
          />
        </div>
      </div>
    </b-col>
    <b-col 
      :xxl="12 - leftSize"
      class="dynamic-dropdown"
      :class="[showFilter ? 'show' : '', noPadding ? '' : 'left-padding']"
      style="background-color: #FFFFFF;"
      :style="noPadding ? 'height: 100%' : 'padding-right: 26px;'"
    >
      <div
        class="d-flex h-100 w-100"
        :class="noPadding ? 'ps-0' : 'ps-2'"
      >
        <slot name="right" />
      </div>
    </b-col>
  </div>
  <!-- end page title -->
</template>

<script>
export default {
  props: {
    leftSize: {
      type: Number,
      default: 6,
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFilter: false
    }
  },
};
</script>
<style lang="scss" scoped>

.dynamic-dropdown {
  @media (max-width: 1399.98px) {
    display: none;
    &.show {
      display: flex;
      box-shadow: 0px 5px 10px rgba(#000000, 0.15);
      padding-top: 1rem;
      padding-bottom: 1rem;
      &.left-padding {
        padding-left: 1rem;
      }
    }    
  }
  @media (min-width: 1399.98px) {
    display: flex;
  }
}
</style>