<template>
  <Layout :items="items">
    <SplitHeader
      :left-size="4"
    >
      <template #left>
        <DashboardHeaderLeft 
          :mode="dashboardMode"
          :fleet-detail="selectedFleet"
          @go-back="backToDashboard"
          @update-data="getFleets"
        />
      </template>
      <template #right>
        <DashboardHeaderRight
          :mode="dashboardMode"
          :is-loading="isLoading"
          :payload-meta="payloadMeta"
          :latest-trip-date="latestTripDate"
          @filter-site="filterSite"
          @set-filter="(val) => filterDate = { ...val }"
          @load-more="loadMoreCounter += 1"
          @set-route-visibility="(val) => enableRoute = val"
        />
      </template>
    </SplitHeader>
    <div
      class="row"
      style="margin-left: -1.5rem; margin-right: -1.5rem"
    >
      <b-col
        class="d-flex px-0 map-container"
        style="margin-top: 1.9rem;"
      >
        <div
          class="dashboard-sidebar px-0 shadow-lg"
          :style="`margin-left: ${ sidebar ? '0%' : '-33.3%'};`"
        >
          <div :class="dashboardMode == 'dashboard' ? 'd-flex flex-column h-100' : 'd-none'">
            <b-card-body>
              <b-tabs justified>
                <b-tab
                  class="m-0 p-0"
                  active
                  @click="tab = 'filter'"
                >
                  <template #title>
                    <div class="fw-bold">
                      Filter Fleet
                    </div>
                  </template>
                </b-tab>
                <b-tab
                  class="m-0 p-0"
                  @click="tab = 'convoy'"
                >
                  <template #title>
                    <div class="fw-bold">
                      Convoy Check
                    </div>
                  </template>
                </b-tab>
              </b-tabs>
            </b-card-body>
            <DashboardFilterFleet 
              v-show="tab == 'filter'"
              class="h-100"
              :device-list="deviceFilterList"
              :reset-trigger="resetFilterCounter"
              @apply-filter="filterFleet" 
              @select-fleet="selectFleet"
            />
            <DashboardConvoySettings
              v-show="tab == 'convoy'"
              class="h-100"
              :device-list="devices" 
              @set-convoy="setConvoy"
            />
          </div>
          <keep-alive>
            <DashboardFleet
              v-if="dashboardMode == 'fleet'"
              :fleet-detail="selectedFleet"
              :filter-date="filterDate"
              :fleet-site-activity="fleetSiteActivity"
              @set-route-visibility="(val) => enableRoute = val"
            />
          </keep-alive>
          <div
            class="toggle-sidebar-button border-0 p-2"
            @click="sidebar = !sidebar"
          >
            <div class="d-flex h-100">
              <i
                class="my-auto"
                :class="`bx ${sidebar ? 'bx-chevron-left' : 'bx-chevron-right'}`"
              />
            </div>
          </div>
        </div>
        <div
          class="px-0"
          :style="`width: ${ sidebar ? '66.7%' : '100%'};`"
          style="transition: all .2s ease-in;"
        >
          <DashboardMap
            v-show="dashboardMode == 'dashboard'"
            class="px-0 h-100"
            :devices="filteredFleets"
            :sites="sites"
            :convoy-data="convoyData"
            @select-fleet="selectFleet"
          />
          <keep-alive>
            <DashboardTraceFleet
              v-if="dashboardMode == 'fleet'"
              class="map-container overflow-auto"
              :selected-fleet="selectedFleet"
              :filter-date="filterDate"
              :enable-route="enableRoute"
              :load-more-counter="loadMoreCounter"
              @update-meta="(val) => payloadMeta = val"
              @update-fleet-site-log="(val) => fleetSiteActivity = val"
            />
          </keep-alive>
        </div>
      </b-col>
    </div>
  </Layout>
</template>

<script>
  import Echo from '@/services/web-socket';
  import Layout from "@/layouts/main.vue";
  import SplitHeader from "@/components/SplitHeader";
  import DashboardMap from "@/components/dashboard/DashboardMap.vue";
  import DashboardConvoySettings from "@/components/dashboard/DashboardConvoySettings.vue";
  import DashboardFilterFleet from "@/components/dashboard/DashboardFilterFleet.vue";
  import DashboardFleet from "@/components/dashboard/DashboardFleet.vue";
  import DashboardHeaderLeft from '@/components/dashboard/DashboardHeaderLeft.vue';
  import DashboardHeaderRight from '@/components/dashboard/DashboardHeaderRight.vue';
  import DashboardTraceFleet from '@/components/dashboard/DashboardTraceFleet.vue';

  import { format, addDays, addMinutes } from 'date-fns';

  export default {
    components: {
      Layout,
      SplitHeader,
      DashboardHeaderLeft,
      DashboardHeaderRight,

      DashboardMap,
      
      DashboardFilterFleet,
      DashboardConvoySettings,
      DashboardFleet,
      DashboardTraceFleet
    },
    data() {
      return {
        isLoading: false,
        sidebar: false,
        tab: 'filter',
        siteCategory: null,

        devices: [],
        filteredFleets: [],
        resetFilterCounter: 0,
        sites: [],
        convoyData: [],

        // Trace Fleet Data
        selectedFleet: {},
        payloadMeta: {},
        enableRoute: true,
        filterDate: {
          start_date: format(new Date(), 'yyyy-MM-dd')+ " 00:00",
          end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
        }, 
        latestTripDate: {},
        fleetSiteActivity: [],
        dashboardMode: 'dashboard',
        loadMoreCounter: 0,
      };
    },
    computed: {
      selectedOrganization() {
        return this.$store.state.organization.selectedOrganization;
      },
      items() {
        if(this.dashboardMode == 'dashboard' ) {
          return [{
            text: "Track Fleet",
            active: true,
          }]
        } return [{
          text: "Track Fleet",
          href: '/',
        }, {
          text: "Trace Fleet",
          active: true,
        },
      ]
      },
      deviceFilterList() {
        return this.devices.map(item => ({
            ...item,
            latest_update: item.last_location?.created_at
          })
        );
      }
    },
    watch: {
      selectedOrganization: {
        immediate: true,
        handler(organizationId) {
          if (organizationId) {
            this.unsubscribePayload()
            this.getData()
          }
        },
      },
      '$route.query.fleet': {
        deep: true,
        immediate: false,
        handler(fleetId) {
          this.traceFleet(fleetId)
        },
      },
    },
    beforeDestroy() {
      // leave channel when leave page
      this.unsubscribePayload()
    },
    methods: {
      async getData() {
        this.isLoading = true
        await this.getFleets(),
        await this.getSites(),
        this.isLoading = false
      },
      async getFleets() {
        try {
          this.devices = []; // Refresh filter fleet
          const { data } = await this.$api.getOrganizationDevices(this.selectedOrganization, { per_page: 200 });
          this.devices = data.data;
          this.filteredFleets = this.devices
          this.subscribePayload();
          if (this.$route.query.fleet) {
            this.traceFleet(this.$route.query.fleet)
          }
        } catch (error) {
          console.log(error);
        }
      },
      async getSites() {
        try {
          const { data } = await this.$api.getOrganizationPlaces(this.selectedOrganization, { category: this.siteCateogry, per_page: 200 });
          this.sites = data.data;
        } catch (error) {
          console.log(error);
        }
      },

      subscribePayload() {
        this.devices.forEach(device => {
          Echo.subscribeChannel(`device.${device.id}.location`)
          // listen new location payloads
          .listen('LocationReceived', (data) => {
            const updatedDevice = this.devices.find((device) => (device.id == data.location.device_id))
            updatedDevice.last_location = data.location;
            const updatedFleet = this.filteredFleets.find((fleet) => (fleet.id == data.location.device_id))
            updatedFleet.last_location = data.location;
          });
        });
      },
      unsubscribePayload() {
        this.devices.forEach(device => {
          Echo.leaveChannel(`device.${device.id}.location`)
        })
      },

      filterFleet(arr) {
        this.filteredFleets = arr
      },
      filterSite(type = null) {
        this.siteCateogry = type
        this.getSites()
      },
      setConvoy(convoyData) {
        if(convoyData.isUpdate) {
          this.convoyData = convoyData.convoy
          return
        } if(convoyData.fleet.length) {
          this.filterFleet(convoyData.fleet)
          this.convoyData = convoyData.convoy
        } else {
          this.filterFleet(this.devices)
          this.convoyData = convoyData.convoy
        }
      },

      selectFleet(fleet) {
        this.$router.push({ query: { fleet: fleet.id } })
      },
      backToDashboard(getData = false) {
        this.$router.push({ query: { } })
        this.selectedFleet = {}
        this.filteredFleets = this.devices
        this.resetFilterCounter += 1
        this.dashboardMode = 'dashboard'
        if(getData) {
          this.getData()
        }
      },

      async traceFleet(fleetId) {
        const fleet = this.devices.find((device) => device.id == fleetId)
        if(fleet) {
          this.dashboardMode = 'fleet'
          this.selectedFleet = fleet
          this.filteredFleets = this.devices.filter((device) => device.id == fleetId)
          this.sidebar = true
          this.getLatestExpedition()
        } else {
          this.backToDashboard()
        }
      },

      async getLatestExpedition() {
        const filter = {
          start_date: format(addDays(new Date(), -7), 'yyyy-MM-dd')+ " 00:00",
          end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
        }
        this.latestTripDate = { ...filter }
        this.isLoading = true
        try {
          const { data } = await this.$api.getDevicePlaceLog(this.selectedFleet.organization_id, this.selectedFleet.id, {
            method: 'geofence',
            start_date: new Date(filter.start_date).toISOString(),
            end_date: new Date(filter.end_date).toISOString(),
            parent_only: true,
          });
          const placeLog = data.data;
          const latestArrival = placeLog.find((item) => item.description == 'arrival')
          const latestDeparture = placeLog.find((item) => item.description == 'departure')

          if(new Date(latestDeparture?.created_at) <= new Date(latestArrival?.created_at)) {
            // Departure is earlier than arrival (Expedition is finished)
            this.latestTripDate.start_date = format(new Date(latestDeparture.statistic_started_at ?? latestDeparture.created_at), 'yyyy-MM-dd HH:mm')
            this.latestTripDate.end_date = format(addMinutes(new Date(latestArrival.created_at), 1), 'yyyy-MM-dd HH:mm')
          } else if(new Date(latestDeparture?.created_at) > new Date(latestArrival?.created_at)) {
            // Arrival is earlier than departure (Expedition still on going)
            this.latestTripDate.start_date = format(new Date(latestDeparture.statistic_started_at ?? latestDeparture.created_at), 'yyyy-MM-dd HH:mm')
            this.latestTripDate.end_date = format(new Date(), 'yyyy-MM-dd') + " 23:59"
          } else {
            this.latestTripDate.start_date = format(new Date(), 'yyyy-MM-dd')+ " 00:00"
            this.latestTripDate.end_date = format(new Date(), 'yyyy-MM-dd') + " 23:59"
          } 
          this.filterDate = { ...this.latestTripDate }
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
    },
  };
</script>

<style lang="scss">
.toggle-sidebar-button {
    position: absolute;
    top: 50%;
    right: -42px;
    font-size: 24px;
    width: 42px;
    height: 52px;
    z-index: 1;
    color: white;
    background-color: black;
    cursor: pointer;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.dashboard-sidebar {
  width: 33.3%; 
  transition: all .2s ease-in; 
  position: relative;
}
.nav-tabs .nav-link {
  &.active {
    background-color: #F3F6F9;
    border-bottom-color: #ececec;
  }
}
</style>
