<template>
  <div class="card-body py-0 overflow-auto">
    <b-row class="mb-3">
      <b-col
        v-for="option in tableFilterOptions"
        :key="option.key"
        lg="6"
      >
        <input
          :id="option.key"
          v-model="filter.status"
          type="radio"
          class="btn-check"
          autocomplete="off"
          :value="option.value"
        >
        <label
          class="d-flex btn btn-light mb-0 w-100 justify-content-between"
          style="height: 40px"
          :for="option.key"
        >
          <div class="d-flex">
            <img
              v-if="option.key == 'show-away'"
              :src="require('@/assets/images/googlemaps/fleet/fleet-yellow.svg')"
              alt=""
              style="max-height: 24px; max-width: 24px;"
              class="my-auto me-2"
            >
            <span
              class="my-auto"
              style="height: 22px; overflow: hidden; text-overflow: ellipsis; font-weight: 600;"
            >
              {{ option.label }}
            </span>
          </div>
          <b-badge
            variant="light"
            class="d-flex"
          >
            <span class="my-auto">
              {{ option.key == 'show-all' ? deviceList.length : awayDevices.length }}
            </span>
          </b-badge>
        </label>
      </b-col>
    </b-row>
    <!-- Search Bar -->
    <SearchBar
      placeholder="Search Fleet"
      :reset-trigger="filter.status"
      @do-search="(val) => { filter.keyword = val }"
      @reset-search="filter.keyword = ''"
    />
    <div class="table-responsive table thead-light mt-3 overflow-auto">
      <DataTable
        :fields="tableFields"
        :has-unit="true"
        :items="deviceOptions"
      >
        <template #header(checkbox)>
          <b-form-checkbox
            :checked="checkedSelectAll"
            @change="toggleSelectAll"
          />
        </template>
        <template #cell(checkbox)="{ row }">
          <b-form-checkbox
            v-model="selectedDevices"
            :value="row.item.id"
          />
        </template>
        <template #cell(vehicle_number)="{ row }">
          <span
            class="link-item-table custom-link"
            style="cursor: pointer;"
            @click="$emit('select-fleet', row.item)"
          >
            {{ row.item.vehicle_number }}
          </span>
        </template>
        <template #cell(brand)="{ row }">
          <img
            v-if="row.item.brand"
            :src="getFleetBrandIcon(row.item.brand)"
            alt=""
            style="max-height: 20px; max-width: 28px;"
            class="my-auto mx-auto"
          >
        </template>
        <template #cell(latest_update)="{ row }">
          <div
            v-if="row.item.last_location?.created_at"
            class="d-flex"
          >
            <div
              class="my-auto me-2"
              :style="`color: ${differenceInHours(new Date(), new Date(row.item.latest_update)) >= 1 ? '#FF7A00' : '#6100FF'}`"
            >
              <i class="bx bxs-circle" />
            </div>
            <div>
              {{ formatDateTime(row.item.last_location.created_at) }}
            </div>
          </div>
          <div v-else>
            -
          </div>
        </template>
        <template #cell(ignition)="{ row }">
          <img
            v-if="row.item.last_location?.can_bus?.ignition != null"
            :src="row.item.last_location?.can_bus?.ignition ? require('@/assets/images/icons/ignition-on.svg') : require('@/assets/images/icons/ignition-off.svg')"
            alt=""
            style="max-height: 20px; max-width: 28px;"
            class="my-auto mx-auto"
          >
        </template>
        <template #cell(is_activated)="{ row }">
          <div class="d-flex">
            <div
              class="d-flex px-2"
              style="white-space: nowrap; border: 1px solid; border-radius: 20px;"
              :class="row.item.is_activated ? 'border-success' : 'border-danger'"
            >
              <i
                class="bx bxs-circle my-auto me-2"
                :class="row.item.is_activated ? 'text-success' : 'text-danger'"
              />
              {{ row.item.is_activated == 1 ? 'Activated' : row.item.is_activated === null ? 'Not Activated' : 'Activation Failed' }}
            </div>
          </div>
        </template>
      </DataTable>
    </div> 
  </div>
</template>

<script>
  import DataTable from "@/components/DataTable";
  import SearchBar from "@/components/SearchBar";
  import { differenceInHours } from 'date-fns';
  import { utcOffset } from '@/utils/constant';
  import { formatDateTime, getFleetBrandIcon } from '@/utils/helper';

  export default {
    components: {
      SearchBar, DataTable
    },
    props: {
      deviceList: {
        type: Array,
        default() {
          return []
        } 
      },
      resetTrigger: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        allDevices: [],
        selectedDevices: [],
        filter: {
          status: '',
          keyword: '',
        },
        tableFields: [
          {
            key: 'checkbox',
            label: '',
            unit: ' ',
          }, {
            key: 'brand',
            label: ' ',
            unit: ' ',
            sortable: true,
          }, {
            key: 'vehicle_number',
            label: 'Vehicle Number',
            unit: '-',
            thStyle: {
              minWidth: '8rem',
            },
            sortable: true,
          }, {
            key: 'driver_name',
            label: 'Driver',
            unit: 'Name',
            sortable: true,
          }, {
            key: 'ignition',
            label: 'IGN',
            unit: '-',
            sortable: true,
          }, {
            key: 'latest_update',
            label: 'Latest Update',
            unit: utcOffset,
            thStyle: {
              minWidth: '12rem',
            },
            sortable: true,
          }, {
            key: 'is_activated',
            label: 'Device',
            unit: 'Activation Status',
            thStyle: {
              minWidth: '9rem',
            },
          }
        ],
        tableFilterOptions: [
          { key: 'show-all', label: 'All Fleets', value: '' },
          { key: 'show-away', label: 'Update >1 Hour Ago', value: 'away' },
        ]
      };
    },
    computed: {
      checkedSelectAll() {
        return this.deviceOptions.every(device => this.selectedDevices.includes(device.id))
      },
      deviceOptions() {
        const options = this.filter.status == '' ? this.allDevices : this.awayDevices
        return options.filter((device) => {
          return (
            (device.vehicle_number ?? 'null').toLowerCase().includes(this.filter.keyword.toLowerCase()) ||
            (device.driver_name ?? 'null').toLowerCase().includes(this.filter.keyword.toLowerCase())
          );
        });
      },
      awayDevices() {
        return this.deviceList.filter((item) => differenceInHours(new Date(), new Date(item.last_location?.created_at)) >= 1)
      }
    },
    watch: {
      resetTrigger: {
        immediate: false,
        handler() {
          this.selectedDevices = this.deviceList.map(device => device.id);
        }
      },
      deviceList: {
        immediate: false,
        handler() {
          if(!this.allDevices.length) {
            this.selectedDevices = this.deviceList.map(device => device.id);
          }
          this.allDevices = this.deviceList;
        }
      },
      selectedDevices: {
        immediate: false,
        handler() {
          this.$emit('apply-filter', this.deviceList.filter(device => this.selectedDevices.includes(device.id)))
        }
      },
      'filter.status': {
        immediate: false,
        handler() {
          this.selectedDevices = this.deviceOptions.map(device => device.id)
        }
      }
    },
    methods: {
      formatDateTime, getFleetBrandIcon, differenceInHours,
      toggleSelectAll() {
        // Deselect
        if (this.checkedSelectAll) {
          this.selectedDevices = []
        } 
        else { 
          this.selectedDevices = this.deviceList.map(device => device.id);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
.btn-check:active+.btn-light,
.btn-check:checked+.btn-light,
.btn-light.active,
.btn-light.dropdown-toggle.show,
.btn-light:active {
    background-color: #F3F6F9;
    color: black;
    box-shadow: none;
    border: 1px solid #CED4DA;
}
.btn-light {
  background-color: #FFFFFF;
  border: 1px solid #F3F6F9;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.badge.bg-light {
  background-color: #FFFFFF !important;
  border: 1px solid #F3F6F9 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}
</style>