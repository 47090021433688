<!-- eslint-disable vue/no-unused-vars -->
<template>
  <Layout :items="items">
    <SplitHeader :left-size="4">
      <template #left>
        <div class="d-flex justify-content-between w-100">
          <h4 class="d-flex mb-sm-0 align-items-center">
            FLEET MANAGEMENT
          </h4>
          <div
            v-if="userRole <= 1"
            class="d-flex gap-2 me-2 my-auto"
          >
            <div>
              <b-button
                variant="outline-primary"
                class="ms-auto"
                data-bs-toggle="modal"
                href="#fleet-model-modal"
              >
                Auto Fill Feature
              </b-button>
            </div>
            <div>
              <b-button
                variant="primary"
                class="ms-auto"
                data-bs-toggle="modal"
                href="#device-form-modal"
                @click="selectedFleet = {}"
              >
                Register Fleet
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <template #right>
        <div class="d-lg-flex gap-2 w-100 my-auto">
          <b-col class="mb-lg-0 mb-3">
            <FormInputSelect
              v-model="filter.brand"
              :options="brandOptions"
              label="Brand"
              :has-icon="true"
            />
          </b-col>
          <b-col class="mb-lg-0 mb-3">
            <FormInputSelect
              v-model="filter.fleet_type"
              :options="typeOptions"
              label="Fleet Type"
            />
          </b-col>
          <b-col class="mb-lg-0 mb-3">
            <FormInputSelect
              v-model="filter.classification"
              :options="classOptions"
              label="Classification"
            />
          </b-col>
          <b-col class="mb-lg-0 mb-3">
            <FormInputSelect
              v-model="filter.can_bus_available"
              :options="canBusOptions"
              label="CAN Bus"
            />
          </b-col>
          <b-col>
            <FormInputSelect
              v-model="filter.is_activated"
              :options="activationOptions"
              label="Activation"
            />
          </b-col>
        </div>
      </template>
    </SplitHeader>
    <b-row>
      <b-col
        style="margin-top: 2.9rem;"
        lg
        class="px-0 px-lg-2"
      >
        <div>
          <Pagination
            :meta-data="fleetsMeta"
            @page-change="handlePageChange"
            @per-page-change="handlePerPageChange"
          >
            <template #button>
              <b-col>
                <SearchBar
                  placeholder="Search Vehicle Number"
                  @do-search="(val) => filter.keyword = val"
                  @reset-search="filter.keyword = null"
                />
              </b-col>
            </template>
            <template #table>
              <div
                v-if="!fleets.length"
                class="text-small text-center mb-3"
              >
                {{ filter.keyword ? 'Sorry, no fleet match your search.' : 'Oops! There is no fleet yet.' }}
              </div>
              <FleetListTable
                v-else
                :fleets="fleets"
                :user-role="userRole"
                :filter-keyword="filter.keyword"
                @select-fleet="(val) => selectedFleet = val"
                @sort-changed="sortData"
              />
            </template>
          </Pagination>
        </div>
      </b-col>
    </b-row>
    <FleetFormModal
      :fleet-detail="selectedFleet"
      :fleet-models="fleetModels"
      @update-data="getFleets"
    />
    <DeleteModal 
      v-if="userRole <= 1"
      entity="Fleet"
      :name="selectedFleet.vehicle_number ?? ''"
      @delete-data="doDelete"
    />
    <FleetModelModal
      :fleet-models="fleetModels"
      @update="getFleetModels"
    />
  </Layout>
</template>

<script>
  import { mapGetters } from "vuex";
  import Layout from "@/layouts/main.vue";

  import SplitHeader from '@/components/SplitHeader.vue';
  import FormInputSelect from '@/components/FormInputSelect.vue';
  
  import FleetFormModal from '@/components/fleet/FleetFormModal.vue';
  import DeleteModal from '@/components/DeleteModal.vue';
  import FleetListTable from '@/components/fleet/FleetListTable.vue';

  import FleetModelModal from '@/components/fleet/FleetModelModal.vue';
  
  import Pagination from '@/components/Pagination.vue';
  import SearchBar from '@/components/SearchBar.vue';
  
  import { useToast } from 'vue-toastification';
  import ToastDefault from '@/components/ToastDefault.vue';
  
  import { capitalize, highlightString } from '@/utils/helper';
  import { fleetBrandOptions, fleetTypeOptions, fleetClassificationOptions, fleetCanBusOptions } from '@/utils/constant';

  export default {
    components: {
      Layout,
      SplitHeader,
      Pagination,
      SearchBar,
      DeleteModal,
      FormInputSelect,
      FleetFormModal,
      FleetListTable,
      FleetModelModal
    },
    setup () {
      const toast = useToast();
      return {
        toast,
      }
    },
    data() {
      return {
        items: [{
            text: "Fleet Management",
            href: "/fleet",
          },
        ],
        brandOptions: [
          { label: 'All', value: "" },
          ...fleetBrandOptions
        ],
        typeOptions: [
          { label: 'All', value: "" },
          ...fleetTypeOptions
        ],
        classOptions: [
          { label: 'All', value: "" },
          ...fleetClassificationOptions
        ],
        canBusOptions: [
          { label: 'All', value: "" },
          ...fleetCanBusOptions
        ],
        activationOptions: [
          { label: 'All', value: "" },
          { label: 'Activated', value: "1" },
          { label: 'Not Activated', value: "null" },
          { label: 'Activation Failed', value: "0" },
        ],

        isLoading: false,
        fleets: [],
        fleetsMeta: {},
        selectedFleet: {},

        fleetModels: [],

        filter: {
          keyword: null,
          brand: '',
          fleet_type: '',
          classification: '',
          can_bus_available: '',
          per_page: 10,
          page: 1,
          sort_by: '',
          direction: ''
        },
      };
    },
    computed: {
      ...mapGetters('organization', ['userRole']),
      selectedOrganization() {
        return this.$store.state.organization.selectedOrganization;
      },
    },
    watch: {
      selectedOrganization: {
        immediate: true,
        handler(organizationId) {
          if (organizationId != null) {
            this.getFleets()
            this.getFleetModels()
          }
        },
      },
      filter: {
        deep: true,
        immediate: true,
        handler() {
          if(this.selectedOrganization != null) {
            this.$router.push({ query: { ...this.filter } })
            this.getFleets()
          }
        }
      }
    },
    methods: {
      highlightString,
      capitalize,
      async getFleets() {
        try {
          this.isLoading = true;
          
          const processedFilter = { ...this.filter }
          if(this.filter.is_activated === '') {
            delete processedFilter.is_activated
          }

          const { data } = await this.$api.getOrganizationDevices(this.selectedOrganization, { ...processedFilter });

          this.fleets = data.data;
          this.fleetsMeta = data.meta;
        } catch (error) {
          console.log(error);
        } finally {
          this.isLoading = false;
        }
      },
      async getFleetModels() {
        try {
          const { data } = await this.$api.getOrganizationFleetModels(this.selectedOrganization);
          this.fleetModels = data.data;
        } catch (error) {
          console.log(error);  
        } finally {
          this.isLoading = false;
        }
      },
      async doDelete() {
        try {
          await this.$api.deleteOrganizationDevice(this.selectedFleet.organization_id, this.selectedFleet.id);           
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'secondary',
              text: 'Deleted successfully',
              icon: 'ri-check-double-line',
            },
          };
          this.toast(toastContent);
          this.getFleets()
        } catch (error) {
          console.log(error);
          const toastContent = {
            component: ToastDefault,
            props: {
              variant: 'danger',
              text: 'Process Failed',
            },
          };
          this.toast(toastContent);
        }
      },
      sortData(sort) {
        this.filter.sort_by = sort.sortBy
        this.filter.direction = sort.direction
        if(sort.sortBy == 'latest_update') {
          this.filter.sort_by = 'last_location.created_at'
        }
      },
      handlePageChange(page) {
        this.filter.page = page
      },
      handlePerPageChange(perPage) {
        this.filter.page = 1
        this.filter.per_page = perPage
      }
    },
  };
</script>